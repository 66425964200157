const form = document.querySelector('form');

form.addEventListener('submit', (event) => {
    event.preventDefault();
    const emailInput = document.querySelector('#email');
    const notification = document.querySelector('.submit-result');
    

    const email = emailInput.value;
    console.log(`User with email ${email} has subscribed!`);
  
    const SibApiV3Sdk = require('sib-api-v3-sdk');
    let defaultClient = SibApiV3Sdk.ApiClient.instance;

    let apiKey = defaultClient.authentications['api-key'];
    apiKey.apiKey = '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';

    let apiInstance = new SibApiV3Sdk.ContactsApi();

    let createContact = new SibApiV3Sdk.CreateContact();

    createContact.email = email;
    createContact.listIds = [3]

    apiInstance.createContact(createContact).then(function(data) {
    // console.log('API called successfully. Returned data: ' + JSON.stringify(data));

    notification.innerHTML= "Thank You for registering! We will get back to you soon.";
    notification.style.visibility= 'visible'

    setTimeout(function() {
        notification.style.visibility = 'hidden';
      }, 4000)

    }, function(error) {
        
        console.error(error.response.body.code);
        
        message= error.response.body.code;
        
        if(message == "duplicate_parameter")
            message= "You already registered! We will get back to you soon.";
        
        notification.innerHTML= message;
        notification.style.visibility= 'visible'
        
        setTimeout(function() {
            notification.style.visibility = 'hidden';
          }, 4000)
    });

});